import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/sign-up'
    },
    {
      path: '/create-virus',
      name: 'create-virus',
      component: () =>
        import('@/views/PageCreateVirus')
    },
    {
      path: '/virus/:virusId',
      name: 'virus',
      props: true,
      component: () =>
        import('@/views/PageVirus')
    },
    // test
    {
      path: '/@test/:virusId',
      redirect: '/test/:virusId'
    },
    {
      path: '/test/:virusId',
      name: 'test',
      props: true,
      component: () =>
        import('@/views/PagePCR')
    },
    {
      path: '/@test-result/:virusId/:uid',
      redirect: '/test-result/:virusId/:uid'
    },
    {
      path: '/test-result/:virusId/:uid',
      name: 'test-result',
      props: true,
      component: () =>
        import('@/views/PagePCRResult')
    },
    // user
    {
      path: '/@user/:uid',
      redirect: '/user/:uid'
    },
    {
      path: '/user/:uid',
      name: 'user',
      props: true,
      component: () =>
        import('@/views/PageUser')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () =>
        import('@/views/PageSettings')
    },
    // auth
    {
      path: '/sign-in',
      name: 'sign-in',
      component: () =>
        import('@/views/PageSignIn')
    },
    {
      path: '/sign-in/:redirectPath',
      name: 'sign-in',
      props: true,
      component: () =>
        import('@/views/PageSignIn')
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () =>
        import('@/views/PageSignIn')
    },
    {
      path: '/sign-up/:redirectPath',
      name: 'sign-up',
      props: true,
      component: () =>
        import('@/views/PageSignIn')
    },
    // redirect
    {
      path: '*',
      redirect: '/'
    }
  ]
})
