const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID
}

const algoliaConfig = {
  appId: process.env.VUE_APP_ALGOLIA_APPID,
  searchKey: process.env.VUE_APP_ALGOLIA_SEARCHKEY,
  index: {
    asset: process.env.VUE_APP_ALGOLIA_INDEX_ASSET,
    assetCreateAtDesc: process.env.VUE_APP_ALGOLIA_INDEX_ASSET_CREATE_DESC,
    assetCreateAtAndImportedNumDesc: process.env.VUE_APP_ALGOLIA_INDEX_ASSET_CREATE_AND_IMPORTED_NUM_DESC,
    comic: process.env.VUE_APP_ALGOLIA_INDEX_COMIC,
    comicPostedAtDesc: process.env.VUE_APP_ALGOLIA_INDEX_COMIC_POSTED_AT_DESC,
    comicPageViewSumNumAndPostedAtDesc: process.env.VUE_APP_ALGOLIA_INDEX_COMIC_PAGE_VIEW_SUM_AND_POSTED_AT_DESC,
    post: process.env.VUE_APP_ALGOLIA_INDEX_POST,
    tag: process.env.VUE_APP_ALGOLIA_INDEX_TAG,
    user: process.env.VUE_APP_ALGOLIA_INDEX_USER,
    poseTemplate: process.env.VUE_APP_ALGOLIA_INDEX_POSE_TEMPLATE
  }
}

// const api = process.env.VUE_APP_API_LOCAL_ORIGIN
const api = process.env.VUE_APP_API_ORIGIN
const domain = process.env.VUE_APP_DOMAIN

// const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN

// const recaptureSiteKey = process.env.VUE_APP_FIREBASE_RECAPTURESITEKEY

// export default config
export { 
  firebaseConfig, algoliaConfig,
  api,
  domain,
  // mixpanelToken,
  // recaptureSiteKey
}
