import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    themes: {
      light: {
        // primary: '#27D6C2',
        primary: '#00cd00',
        secondary: '#27D6C2'
      },
      dark: {
        // primary: '#27D6C2',
        primary: '#00cd00',
        secondary: '#27D6C2'
      }
    },
  }
})
