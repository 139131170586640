// import mixpanel from '@/components/utils/mixpanel'

export default {
  computed: {
    isMobile () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (
        userAgent.includes('iphone') ||
        userAgent.includes('ipad') ||
        userAgent.includes('android') ||
        userAgent.includes('mobile')
      )
    },
    isiPad () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (
        userAgent.includes('ipad') ||
        userAgent.indexOf('ipad') > -1 ||
        (userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document)
      )
    },
    isSafari () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (userAgent.indexOf('safari') > -1) && (userAgent.indexOf('chrome') === -1)
    }
  },
  data () {
    return {
      pallete: {
        // $active_color: '#1ed6c2',
        $active_color: '#00cd00',
        $deactive_color: '#757575',
        $tertiary_bg_color: '#f7f7f7',
        $primary_text_color: '#2a2a2a',
        $primary_bg_color: '#fff',
        $secondary_text_color: '#757575',
        $secondary_bg_color: '#e0e0e0',
        $secondary_active_color: '#757575',
        $dark_primary_bg_color: '#000',
        $overlay_color: 'rgba(0, 0, 0, 0.7)',
        $safe_color: '#1976d2',
        $green_color: '#1DC1AC',
        $favorite_color: '#FBC02D',
        $like_color: '#ff5252',
        $dangerous_color: '#ff5252',
        $gray_color: '#999'
      },
      hrefTermsOfService: 'https://snz.tw/serviceterms.pdf',
      hrefPrivacyPolicy: 'https://snz.tw/privacypolicy.pdf',
      hrefCompany: 'https://note.com/kooh/n/ne2e734abe879',
      // hrefCompany: 'https://about.snz.tw'
    }
  },
  methods: {
    // for tracking
    // sendMixpanelEvent ({ msg, info={} }) {
    //   mixpanel.track(msg, info)
    //   window.gtag('event', msg, info)
    //   // console.log('sendMixpanelEvent:', { msg, info })
    // },
    // util
    loadImage (src, crossOrigin) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        if (crossOrigin) image.crossOrigin = crossOrigin
        image.onload = () => resolve(image)
        image.onerror = (e) => reject(e)
        image.src = src
      })
    },
    sleep (msec) {
      return new Promise(resolve => setTimeout(resolve, msec))
    },
    numStr (num) {
      if (num >= 1000) return `${num/1000}K`
      else if (num >= 1000000) return `${num/1000000}M`
      else return num
    },
    // dateToStr (date) {
    //   if (isToday(date)) {
    //     return format(date, 'HH:mm')
    //   } else if (isThisYear(date)) {
    //     return format(date, 'MM/dd')
    //   } else {
    //     return format(date, 'yyyy/MM/dd')
    //   }
    // },
    bigNumToStr (num, show0=false) {
      if (num === 0 && !show0) return '　'
      if (num < 1000) {
        return num
      } else {
        return `${num/1000}K`
      }
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str;
      } else {
        return str.slice(0, num > 3 ? num - 3 : num) + '...';
      }
    },
    secondsToDate (secs) {
      const t = new Date(1970, 0, 1)
      t.setSeconds(secs)
      return t
    },
    getUniqueStr (myStrong) {
      let strong = 1000
      if (myStrong) strong = myStrong
      return new Date().getTime().toString(16)  + Math.floor(strong*Math.random()).toString(16)
    },
    validURL (str) {
      const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
      return regexp.test(str)
        && (str.includes('http://') || str.includes('https://'))
    },
    replaceURLsWithLinks (text) {
      const urlRegex = /(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*/gi
      const urls = text.match(urlRegex)
    
      if (!urls) return text
    
      urls.forEach(url => {
        const link = '<a href="' + url + '" target="_blank">' + url + '</a>';
        text = text.replace(url, link);
      })
    
      return '<span>' + text + '</span>'
    }
  }
}