import { 
  firebaseConfig, api, domain,
  // recaptureSiteKey
} from './config'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/app-check'

const firebaseApp = firebase.initializeApp(firebaseConfig)

const firestore = firebaseApp.firestore()
const auth = firebaseApp.auth()
const storage = firebaseApp.storage()
const functions = firebase.app().functions('asia-northeast1')
const FieldValue = firebase.firestore.FieldValue

if (window.location.hostname == 'localhost') {
  // window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
  // functions.useEmulator('localhost', 5000)
}

// const appCheck = firebase.appCheck()
// appCheck.activate(recaptureSiteKey, true)

export default firestore
export { firebase, auth, storage, functions, api, domain, FieldValue }
