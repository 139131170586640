<template lang="pug">
v-app
  v-content
    //- router-view
    router-view(v-if="routeNamesNeedFullUpdate.includes($route.name)"
      :key="$route.fullPath")
    router-view(v-else)
    SharedConfirm
</template>

<style lang="scss">
@import "./plugins/meltline.css";
@import "@/scss/_variables.scss";
@import "@/scss/common.scss";
</style>

<script>
import SharedConfirm from '@/components/shared/SharedConfirm'

export default {
  name: 'App',
  components: {
    SharedConfirm
  },
  data () {
    return {
      routeNamesNeedFullUpdate: []
    }
  },
  mounted () {
    // this.appHeight()
    window.addEventListener('resize', this.appHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.appHeight)
  },
  methods: {
    appHeight () {
      const doc = document.documentElement
      doc.style.setProperty('--vh', (window.innerHeight*.01) + 'px')
    }
  }
}
</script>
