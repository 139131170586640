// import { firebase } from '@/components/utils/firebase'

export const state = () => ({
  test: null
})

export const mutations = {
  updateTest(state, value) {
    state.test = value
  }
}

export const actions = {
  // async test({ dispatch, commit }, test) {
  //   commit('updateTest', test)
  // }
}
